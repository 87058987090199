import { Injectable } from '@angular/core';
import { OnInit, NgZone, } from '@angular/core';
import { ActivatedRoute, Router, ParamMap, NavigationExtras } from '@angular/router';
import { ToastController, NavController, ModalController, AlertController, NavParams, Platform, } from '@ionic/angular';
import { Printer, PrintOptions } from '@ionic-native/printer/ngx';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { LoginProvider } from '../providers/login'
import { ImporterProvider } from '../providers/importer'
import { GenerateWorkbookProvider } from '../providers/generateworkbook'
import { CustomerProvider } from '../providers/customer';
import { Message, Mask, DateTms } from '../entities/dataSystemBase'

import { ResponsePlanilha, ContractLimit, ContractLimitsST, Customer, Customers, Operation, Operations, SummaryOperation, SummaryOperations, Workbook, GenerateWorkbook, User } from '../entities/dataCash'
export {
    OnInit, Platform,
    ActivatedRoute, Router, NgZone, NavigationExtras, NavParams,
    NavController, ToastController, AlertController, ModalController, ParamMap, switchMap, Observable, InAppBrowser,Printer,

    LoginProvider, ImporterProvider, GenerateWorkbookProvider,
    ResponsePlanilha, ContractLimit, ContractLimitsST, Customer, Customers, Operation, Operations, SummaryOperation, SummaryOperations,
    Workbook, GenerateWorkbook, CustomerProvider, User,
    Message, Mask, DateTms
}
@Injectable()
export class MainPage implements OnInit {

    dataReturned: any;
    temp: any;
    //banks: Array<any> = new Array<any>({ nickName: "BTG" }, { nickName: "BTG1" }, { nickName: "BTG2" })
    banks: Array<any> = new Array<any>()
    bankFile: any;
    file: File;
    constructor(public route: ActivatedRoute, public ngZone: NgZone,
        public router: Router, protected navCtrl: NavController, protected toastCtrl: ToastController,
        protected alertCtrl: AlertController, public modalCtrl: ModalController, public iab: InAppBrowser,
        public loginPro: LoginProvider, public importerPro: ImporterProvider, public generateWorkbookPro: GenerateWorkbookProvider,
        public customerPro: CustomerProvider, public printer: Printer
    ) {
        this.loginPro.setBase();
        this.banks = this.loginPro?.banks ?? new Array<any>();
        if (this.banks.length == 0 && this.loginPro.variables.accessToken != undefined) {
            this.loginPro.getBank().then(data => {
                this.banks = this.loginPro.banks;
            })    .catch(error=>{
        //        this.getErroPresentAlert(error)
              });
        }

    }
    newGuid(){
      return  this.newGuid();
    }
    ngOnInit() {
        //alert(this.loginPro.getPlatform())
        //alert("ngOnInit MainPage");
    }
    ionViewWillEnter() {
        this.banks = this.loginPro?.banks ?? new Array<any>();
       /* if (this.banks.length == 0 && this.loginPro.variables.accessToken != undefined) {
            this.loginPro.getBank().then(data => {
                this.banks = this.loginPro.banks;
            })    .catch(error=>{
                this.getErroPresentAlert(error)
              });
        }*/
    }

    //-------------------------------

    print(print:any) {
        //this.printer.isAvailable().then((onSuccess: any) => {
            let content = document.getElementById(print).innerHTML;
            let options: PrintOptions = {
                name: 'MyDocument',
                duplex: true,
                orientation: "portrait",
                monochrome: true
            };
            this.printer.print(content, options).then((value: any) => {
                console.log('value:', value);
            }, (error) => {
                console.log('eror:', error);
            });
      //  }, (err) => {
      //      console.log('err:', err);
      //  });
    }
    //-------------------------------

    Close() {
        this.router.navigate(['login']);
        this.loginPro.setCleanBase();
    }
    openFile() {
        return new Promise((resolve) => {
            var x = document.createElement("INPUT");
            x.setAttribute("type", "file");
            x.addEventListener('change', function (e: Event) {
                resolve((<HTMLInputElement>e.target).files[0])
            });
            x.click()
        });
    }

    onfile(bank) {
        this.openFile().then((file: File) => {
            let formData = new FormData();
            formData.append("Planilha", file, file.name);
            formData.append("bank", bank);
            this.importerPro.postImporter(formData, bank)
                .then(data => {
                    this.router.navigate(['operation']);
                })
                .catch(error => {
                    this.getErroPresentAlert(error)
                })
        })
    }

    onFileChange(fileChangeEvent, bank) {

        var file = fileChangeEvent.target.files[0];
        let formData = new FormData();
        formData.append("Planilha", file, file.name);
        formData.append("bank", bank);

        this.importerPro.postImporter(formData, bank)
            .then(data => {

                this.router.navigate(['operation']);
                //  alert(JSON.stringify(data));
            })
            .catch(error => {
                this.getErroPresentAlert(error)
                this.router.navigate(['operation']);
            })
    }
    presentAlertMessage(name) {
        this.loginPro.presentAlert(this.loginPro.getResourceMessage(name))
    }
    getResourceMessage(name) {
        return this.loginPro.getResourceMessage(name)
    }
    getResourceLabel(name) {
        return this.getResourceLabel(name)
    }
    getResources() {

    }
    openUrl(link) {
        const browser = this.iab.create(link);
    }
    pagebase() {
        this.router.navigate(['home']);
    }

    isNull(myObject) {
        return ((myObject != null) && (typeof (myObject) != undefined) && (myObject != ""));
    }
    isNullList(myObject) {
        return ((myObject != null) && (typeof (myObject) != undefined) && (myObject != "") && myObject.length > 0);
    }

    getErroPresentAlert(error) {
        this.loginPro.presentAlert(error.message)
        if(this.loginPro.variables.accessToken == undefined && this.loginPro.getItem('accessToken') == 'Login'){
            this.Close();
        }
    }
    getMessage(tipe) {
        //return this.loginPro.resource
    }
    formatDate(value: any, format: string) {
        return new DateTms().format(value, format);
    }
    formatDenaryNum(value: any, denary: number) {
        if (Number.isNaN(value)) {
            value = 0.0;
        }
        if (denary > 0) {
            value = parseFloat(value).toFixed(denary)

        } else {
            value = parseFloat(value);

        }
    }
    formatDenary(value: any, denary: number) {
        let _length
        if (Number.isNaN(value)) {
            value = 0.0;
        }
        value = value.toString().replace(',', '.');
        if (denary > 0) {
            value = parseFloat(value).toFixed(denary)

        } else {
            value = parseFloat(value);

        }

        _length = Math.abs(parseFloat(value.toString().substr(0, value.toString().indexOf('.')))).toString().length;
        if (value.toString().indexOf('.') == -1) {
            value = value.toString() + '.00';
        } else if (value.toString().substr(value.toString().indexOf('.'), value.toString().length) == 1) {
            value = value.toString() + '0';
        }
        value = value.toString().replace('.', ',');

        if (_length >= 4) {
            value = value.toString().substr(0, value.toString().indexOf(',') - 3) + '.' + value.toString().substr(value.toString().indexOf(',') - 3, value.toString().length);
        }
        if (_length >= 7) {
            value = value.toString().substr(0, value.toString().indexOf('.') - 3) + '.' + value.toString().substr(value.toString().indexOf('.') - 3, value.toString().length);

        }
        if (_length >= 10) {
            value = value.toString().substr(0, value.toString().indexOf('.') - 3) + '.' + value.toString().substr(value.toString().indexOf('.') - 3, value.toString().length);

        }
        if (_length >= 13) {
            value = value.toString().substr(0, value.toString().indexOf('.') - 3) + '.' + value.toString().substr(value.toString().indexOf('.') - 3, value.toString().length);

        }
        if (_length >= 16) {
            value = value.toString().substr(0, value.toString().indexOf('.') - 3) + '.' + value.toString().substr(value.toString().indexOf('.') - 3, value.toString().length);

        }

        // return this.formatValue(value,2).toString().replace('.', ',');

        return value;
    }


}