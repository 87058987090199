

import { Injectable } from '@angular/core';
import {Settings} from '../entities/settings';
import {EntryPoint,InfBase} from './dataSystemBase';


@Injectable()
export class Variables {
  timeout: any;
  retry: any;
  platform: any;
  languageType:number=0; //0 português, 1 inglês,2 italiano;
  entryPointUrls:Array<any>= new Array<any>();
  base64_chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  idDevice:any;
  accessToken:any;
  infBase:any=new InfBase()
  constructor(public settings:Settings) {
    this.entryPointUrls = [
      { url: "https://www.easycomm.com.br/EntryPoint/EntryHandler.ashx", 
        headers: [{name:'tecms-operation',value:'GetAddress'}], 
        body:{AppKey: this.settings.appGuid },
        entryPoint:EntryPoint },
      { url: "https://www.atendeja.com.br/EntryPoint/EntryHandler.ashx", 
        headers: [{name:'tecms-operation',value:'GetAddress'}], 
        body:{AppKey: this.settings.appGuid }, 
        entryPoint:EntryPoint }
    ];
    
    this.timeout = 100000;
    this.retry = 0;
   // this.idDevice='teste'
  }
}





