import { Injectable } from '@angular/core';
import { BaseComunicationProvider } from '../providers/basecomunication/basecomunication';
import { HttpHeaders } from '@angular/common/http';
import { ResponsePlanilha, } from './../entities/dataCash'
@Injectable()
export class ImporterProvider extends BaseComunicationProvider {
    address = "Importer";

    responsePlanilha: ResponsePlanilha
    postImporter(formData: FormData, bank) {
        return new Promise((resolve, reject) => {
            this.presentLoadingTMS();
            var _headers =this.httpHeaders();
            this.httpPost(this.settings.baseUrl + "Importer", _headers, formData)
                .then((data: any) => {
                    this.responsePlanilha = new ResponsePlanilha(data, bank);
                    this.dismissLoadingTMS();
                    resolve(this.requestResult)
                }).catch((erro) => {
                    this.responsePlanilha = new ResponsePlanilha();
                    this.dismissLoadingTMS();
                    reject(erro)
                })
        })
    }
    getBank() {
        return new Promise((resolve, reject) => {
            this.presentLoadingTMS();
            var _headers =this.httpHeaders();
            this.httpGet(this.settings.baseUrl + "Bank", _headers, '')
                .then((data: any) => {
                    this.dismissLoadingTMS();
                    resolve(data)
                }).catch((erro) => {
                    this.dismissLoadingTMS();
                    reject(erro)
                })
        })
    }


}