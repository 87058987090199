import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule} from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Device } from '@ionic-native/device/ngx';
import { Network } from '@ionic-native/network/ngx';

import { File } from '@ionic-native/file/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Printer } from '@ionic-native/printer/ngx';

import {LoginProvider} from './../providers/login'
import {ImporterProvider} from './../providers/importer';
import {GenerateWorkbookProvider} from './../providers/generateworkbook';
import {CustomerProvider} from '../providers/customer';
import {Variables}from './../entities/variables';
import {Settings}from './../entities/settings';
//import {Resource} from './../entities/resource'
//import {ResourceBase}from '../entities/resourceSystemBase';
//import {InfBase} from '../entities/dataSystemBase'
import {LocalStorageService} from '../providers/basecomunication/localstorage'
import { SelectCustomerPage } from './page/select-customer/select-customer.page';
import { CalendarModule } from "ion2-calendar";
@NgModule({
  declarations: [AppComponent,SelectCustomerPage],
  entryComponents: [SelectCustomerPage],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,HttpClientModule,CalendarModule,
    NgCircleProgressModule.forRoot()],
  providers: [    StatusBar,
    SplashScreen,File,InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LoginProvider,ImporterProvider,GenerateWorkbookProvider,CustomerProvider,
    Variables,Settings,LocalStorageService,
     Device,Network,Printer
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
