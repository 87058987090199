import { Injectable } from '@angular/core';
import { Settings } from './settings'


@Injectable()

export class EntryPoint {
  address: string;
  appAddressId: number;
  appId: number;
  customerId: number;
  isValid: boolean;
  key: string;
  parameters: Array<any> = new Array<any>()
  constructor(items?: any) {
    if (items != undefined) {
      this.address = items.address || items.Address;
      this.appAddressId = items.appAddressId || items.AppAddressId;
      this.appId = items.appId || items.AppId;
      this.customerId = items.customerId || items.CustomerId;
      this.isValid = items.isValid || items.IsValid;
      this.key = items.key || items.Key;
      this.parameters = new Array<any>(items.parameters || items.Parameters);
    } else {
      this.address = null;
      this.appAddressId = null;
      this.appId = null;
      this.customerId = null;
      this.isValid = null;
      this.key = null;
      this.parameters = new Array<any>();
    }
  }
}

@Injectable()
export class ErroSystem {
  status: any;
  message: Message;
  item: any;
  constructor(status, item?, message?, erroResource?) {
    this.status = status;
    this.message = new Message()
    if (erroResource != undefined && message == undefined) {
      this.message = erroResource
    } else if (erroResource != undefined && message == undefined) {
      this.message = erroResource
      this.message.message = message + this.message.message
    } else {
      this.message.message = message.replace('\n', '<br/>')
    }
    this.item = item;
  }
}
/*
@Injectable()
export class ErroSystem2 {
  status: any;
  message: any;
  item: any;
  constructor(status, item?, message?, erroResource?, languageType?) {
    this.status = status;
    if (languageType != undefined && erroResource != undefined && message == undefined) {
      this.message = erroResource[languageType].message
    } else if (languageType != undefined && erroResource != undefined && message == undefined) {
      this.message = message + erroResource[languageType].message
    } else {
      this.message = message
    }
    this.item = item;


  }

}*/

export class InfBase {
  languages: Array<Type>;
  typesResource: Array<Type>;
  resources: Array<Resource>
  constructor(items?: any) {
    if (items != undefined) {
      this.languages = new Array<Type>();
      if (items != undefined && items.languages != undefined) {
        items.languages.map(item => {
          this.languages.push(new Type(item));
        })
      }
      this.typesResource = new Array<Type>();
      if (items != undefined && items.typeResources != undefined) {
        items.typeResources.map(item => {
          this.typesResource.push(new Type(item));
        })
      }
      this.resources = new Array<Resource>();
      if (items != undefined && items.resources != undefined) {
        items.resources.map(item => {
          this.resources.push(new Resource(item));
        })
      }

    }
  }
}
export class Resource {
  name: any;
  typsResourceId: number;
  labels: Array<Label>;
  messages: Array<Message> = new Array<Message>();
  constructor(items?: any) {
    if (items != undefined) {
      this.name = items.name;
      this.typsResourceId = items.typsResourceId;
      this.labels = new Array<Label>();
      if (items != undefined && items.labels != undefined) {
        items.labels.map(item => {
          this.labels.push(new Label(item));
        })
      }
      this.messages = new Array<Message>();
      if (items != undefined && items.messages != undefined) {
        items.messages.map(item => {
          this.messages.push(new Message(item));
        })
      }
    }

  }

}

export class Type {
  id: number;
  name: string;
  constructor(items?: any) {
    if (items != undefined) {
      this.id = items.id;
      this.name = items.name;
    }
  }

}

export class Label {
  languagesId: number;
  label: string;
  constructor(items?: any) {
    if (items != undefined) {
      this.languagesId = items.languagesId;
      this.label = items.label;
    }
  }
}



export class Message {
  languagesId: number;
  header: string;
  subHeader: string;
  message: string;
  constructor(items?: any) {
    if (items != undefined) {
      this.languagesId = items.languagesId;
      this.header = items.header;
      this.subHeader = items.subHeader;
      this.message = items.message;
    } else {
      this.languagesId = null;
      this.header = null;
      this.subHeader = null;
      this.message = null;
    }
  }
}


export class Mask {
  private auxdata: any = '';
  constructor() { }
  maskCPF_CNPJ(data) {
    var idValue = data.replace('.', '').replace('.', '').replace('-', '').replace('/', '');
    var idType = 0;
    if (data.length > this.auxdata.length) {
      if (idValue.length >= 3 && idValue.length <= 6) {
        data = idValue.substr(0, 3) + '.' + idValue.substr(3, 3);
      } else
        if (idValue.length > 6 && idValue.length < 9) {
          data = idValue.substr(0, 3) + '.' + idValue.substr(3, 3) + '.' + idValue.substr(6, 3);
        } else
          if (idValue.length >= 9 && idValue.length <= 11) {
            data = idValue.substr(0, 3) + '.' + idValue.substr(3, 3) + '.' + idValue.substr(6, 3) + '-' + idValue.substr(9, 2);
          } else
            if (idValue.length > 11 && idValue.length <= 12) {
              idType = 1;
              data = idValue.substr(0, 2) + '.' + idValue.substr(2, 3) + '.' + idValue.substr(5, 3) + '/' + idValue.substr(8, 4);
            } else
              if (idValue.length > 12) {
                idType = 1;
                data = idValue.substr(0, 2) + '.' + idValue.substr(2, 3) + '.' + idValue.substr(5, 3) + '/' + idValue.substr(8, 4) + '-' + idValue.substr(12, 2);
              }
      this.auxdata = data;

      return { idType: idType, idValue: idValue, idValueMask: this.auxdata };
    } else if (data.length < this.auxdata.length) {
      this.auxdata = data;
      return { idType: idType, idValue: idValue, idValueMask: this.auxdata };
    } else {
      data = this.auxdata;
      return { idType: idType, idValue: idValue, idValueMask: this.auxdata };
    }
  }
}

export class DateTms {
  private year: number =-1;
  private month: number=-1;
  private day: number=-1;
  private hours: number=-1;
  private minutes: number=-1;
  private seconds: number=-1;
  private milliseconds: number=-1;

  date: Date = new Date();
  constructor(items?: any) {
    if (items instanceof Date) {
      this.dateToDate(items)
    } else
      if (typeof items === 'string') {
        this.strToDate(items)
      }

  }

  format(items: any, type: string): string {
    if (items != undefined) {


      if (items instanceof Date) {
        this.dateToDate(items)
      } else
        if (typeof items === 'string') {
          this.strToDate(items)
        }
      if ( (this.year>0&& this.month >=0 && this.day >0 && this.hours>0&& this.minutes>0&& this.seconds>0&&  this.milliseconds >0)&& 
        
        type.toUpperCase().indexOf('YYYY') > 0 || type.toUpperCase().indexOf('YY') > 0 || type.toUpperCase().indexOf('MM') > 0 ||
        type.toUpperCase().indexOf('DD') > 0 || type.toUpperCase().indexOf('HH') > 0 || type.toUpperCase().indexOf('MI') > 0 ||
        type.toUpperCase().indexOf('SE') > 0 || type.toUpperCase().indexOf('MS') > 0) {

        return type.toUpperCase().replace('YYYY', this.year.toString())
          .replace('YY', this.year.toString().substr(2, 2))
          .replace('MM', (this.month + 1).toString().length == 1 ? '0' + (this.month + 1).toString() : (this.month + 1).toString())
          .replace('DD', this.day.toString().length == 1 ? '0' + this.day.toString() : this.day.toString())
          .replace('HH', this.hours.toString().length == 1 ? '0' + this.hours.toString() : this.hours.toString())
          .replace('MI', this.minutes.toString().length == 1 ? '0' + this.minutes.toString() : this.minutes.toString())
          .replace('SE', this.seconds.toString().length == 1 ? '0' + this.seconds.toString() : this.seconds.toString())
          .replace('MS', this.milliseconds.toString().length == 1 ? '0' + this.milliseconds.toString() : this.milliseconds.toString())
      } else
        return '';
    } else
      return '';
  }
  private strToDate(items: string) {
    if (items != undefined) {
      this.year = parseInt(items.substr(0, 4));
      this.month = parseInt(items.substr(4, 2)) - 1;
      this.day = parseInt(items.substr(6, 2));
      this.hours = parseInt(items.substr(8, 2)) || 0;
      this.minutes = parseInt(items.substr(10, 2)) || 0;
      this.seconds = parseInt(items.substr(12, 2)) || 0;
      this.milliseconds = parseInt(items.substr(14, 2)) * 10 || 0;
      this.date = new Date(this.year, this.month, this.day, this.hours, this.minutes, this.seconds, this.milliseconds);
      return this.date;
    }
  }
  private dateToDate(items: Date) {
    if (items != undefined && items instanceof Date) {
      this.year = items.getFullYear();
      this.month = items.getMonth();
      this.day = items.getDate();
      this.hours = items.getHours();
      this.minutes = items.getMinutes();
      this.seconds = items.getSeconds();
      this.milliseconds = items.getMilliseconds();
      this.date = new Date(this.year, this.month, this.day, this.hours, this.minutes, this.seconds, this.milliseconds);
      return this.date;
    }
  }
}