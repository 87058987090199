

import { Injectable } from '@angular/core';



@Injectable()
export class LocalStorageService {

    setItem = function (variables,key, value) {
        var storage = localStorage;
        var aGuid=''
        if (variables.appGuid !=null){
            aGuid=variables.appGuid 
        }
        storage.setItem(aGuid +variables.appName+variables.version + key, value);
        //alert("setItem:"+this.variables.appGuid+":" + this.variables.appName+":" + key)
    };

    getItem = function (variables,key) {
        var storage = localStorage;
        var aGuid=''
        if (variables.appGuid !=null){
            aGuid=variables.appGuid 
        }
        //alert("getItem:"+this.variables.appGuid+":" + this.variables.appName+":" + key)
        return storage.getItem(aGuid + variables.appName+variables.version + key);
    }

    removeItem = function (variables,key) {
        var storage = localStorage;
        var aGuid=''
        if (variables.appGuid !=null){
            aGuid=variables.appGuid 
        }
       // alert("removeItem:"+this.variables.appGuid+":" + this.variables.appName+":" + key)
        storage.removeItem(aGuid + variables.appName+variables.version + key);
    }
}
