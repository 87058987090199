import { Injectable } from '@angular/core';
import { BaseComunicationProvider } from '../providers/basecomunication/basecomunication';

@Injectable()
export class GenerateWorkbookProvider extends BaseComunicationProvider {

    path: string = null;
    postGenerateWorkbook(data) {
        return new Promise((resolve, reject) => {
            this.presentLoadingTMS();
            var _headers =this.httpHeaders();
            this.httpPost(this.settings.baseUrl + "GenerateWorkbook", _headers, data)
                .then((data: any) => {
                    this.path = data.path;
                    this.dismissLoadingTMS();
                    resolve(data)
                }).catch((erro) => {
                    this.path = null;
                    this.dismissLoadingTMS();
                    reject(erro)
                })
        })
    }

}