import { Injectable, OnInit } from '@angular/core';

//import { Observable, throwError } from 'rxjs';
/*import { Platform, LoadingController,AlertController } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { Network } from '@ionic-native/network/ngx';


import { LocalStorageService } from '../basecomunication/localstorage';
*/
import { HttpBaseProvider, HttpClient, Variables, Settings, File, ErroSystem, HttpHeaders } from '../basecomunication/httpbase';

@Injectable()

export abstract class BaseComunicationProvider extends HttpBaseProvider {

    nl_auth: any;
    loader: any;
    isLoading = false;
    // headers:HttpHeaders;

    /* idDevice:any;
     constructor( public http: HttpClient, public variables: Variables, public settings: Settings, public file: File,
         public localStorage:LocalStorageService,
         public platform: Platform, public loadingCtrl: LoadingController,public alertController:AlertController,
         public device: Device, public network: Network) {
             super(http,variables,settings,file)
             this.variables.idDevice=this.getItem('idDevice')
             this.variables.accessToken=this.getItem('accessToken')
             if (this.variables.idDevice ==undefined ){
                 this.variables.idDevice=this.device.uuid || this.newGuid();
                 this.setItem('idDevice',  this.variables.idDevice)
             }

             this.setBase();
            /*let _headers = new HttpHeaders().set('tms-device',   this.variables.idDevice );
            if (this.variables.accessToken!= undefined) {
                _headers = _headers.set('Authorization', 'Bearer ' +this.variables.accessToken);
            }*
            }*/

     setCleanBase(){
         if (this.settings.checkEntryPoint){
            this.variables.idDevice = undefined;
            this.removeItem('idDevice')
           this.settings.baseUrl = undefined;
           this.removeItem('baseUrl');
           this.variables.accessToken= undefined;
           this.removeItem('accessToken');
         }else{
            this.variables.idDevice=this.settings.appName;
            this.removeItem('idDevice')
            this.removeItem('baseUrl');
            this.removeItem('accessToken');
            this.setItem('baseUrl', this.settings.baseUrl);
            this.setItem('idDevice', this.variables.idDevice);
         }


     }

    setBase() {
        return new Promise((resolve, reject) => {
            let idDevice = this.getItem('idDevice');
            if (idDevice && idDevice != 'undefined' && idDevice != 'null')
                this.variables.idDevice = idDevice;
            let baseUrl = this.getItem('baseUrl');
            if (baseUrl && baseUrl != 'undefined' && baseUrl!='null')
                this.settings.baseUrl = baseUrl;
            this.variables.accessToken=this.getItem('accessToken');

            if (this.variables.idDevice == undefined || this.settings.baseUrl == undefined) {
                this.presentLoadingTMS();
                this.listEntryPointUrl()
                    .then((data: any) => {
                        this.dismissLoadingTMS();
                        this.variables.idDevice = this.device.uuid || this.newGuid()
                        this.setItem('idDevice', this.variables.idDevice)
                        resolve(this.variables.idDevice)
                    })
                    .catch((error: Array<ErroSystem>) => {
                        this.variables.idDevice = null;
                        var message: any = null;
                        var status: any = null;
                        var textmessage: any = '';
                        var textstatus: any = '';
                        //alert('erro: '+JSON.stringify(erro));
                        error.map(e => {
                            if (status != e.status || message != e.message[0].message) {
                                status = e.status,
                                    message = e.message[0].message
                                if (textstatus != '') {
                                    textstatus = textstatus + ', '
                                    textmessage = textmessage + ', '
                                }
                                textstatus = textstatus + status;
                                textmessage = textmessage + message;
                            }
                        })
                        this.dismissLoadingTMS();
                        this.presentAlert({ header: 'EntryPointUrl', subHeader: textstatus, message: textmessage })
                        reject(error)
                    });
            }else{
                resolve(this.variables.idDevice)
            }
        })
        /*this.listEntryPointUrl()
        .then((data:any)=>{
            this.setResource()
            .then((data:any)=>{
                this.variables.idDevice=this.device.uuid || this.newGuid();
            })
            .catch((error:any)=>{
                this.variables.idDevice=null;
                this.presentAlert(error.message)
            })
            //alert(JSON.stringify(data));
        })
        .catch((erro:Array<ErroSystem>)=>{
            this.variables.idDevice=null;
            var message:any =null;
            var status:any=null;
            var textmessage:any ='';
            var textstatus:any ='';
          //alert('erro: '+JSON.stringify(erro));
            erro.map(e => {
                if (status != e.status || message != e.message[0].message){
                    status = e.status,
                    message = e.message[0].message
                    if (textstatus != ''){
                        textstatus=textstatus+', '
                        textmessage=textmessage+', '
                    }
                    textstatus=textstatus+status;
                    textmessage=textmessage+message

                }
            })
          this.presentAlert({header:'EntryPointUrl',subHeader:textstatus,message:textmessage})

        });*/
    }

    async presentAlert(text) {
        const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: text.header || '',
            subHeader: text.subHeader || '',
            message: text.message,
            buttons: ['OK']
        });

        await alert.present();
    }
    getPlatform() {
        try {
            if (this.platform.is('android')) {
                this.variables.platform = 'android'
            } else if (this.platform.is('ios')) {
                this.variables.platform = 'ios'
            } else if (this.platform.is('iphone')) {
                this.variables.platform = 'iphone'
            } else if (this.platform.is('mobile')) {
                this.variables.platform = 'mobile'
            } else {
                this.variables.platform = 'browser'
            }
        } catch (err) {

        }

    }
    /*  newGuid() {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
              var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
              return v.toString(16);
          });
      }
  */
    async presentLoadingTMS(message: string = null) {
        this.isLoading = true;
        return await this.loadingCtrl.create({
            cssClass: 'my-custom-class',
            message: message,
            duration: 6000,
        }).then(a => {
            a.present().then(() => {
                console.log('presented');
                if (!this.isLoading) {
                    a.dismiss().then(() => console.log('abort presenting'));
                }
            });
        });
    }

    async dismissLoadingTMS() {
        this.isLoading = false;
        return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));

    }

}