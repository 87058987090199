
import { Injectable } from '@angular/core';


@Injectable()
export class Settings {
    appName: string;
    version: string;
    appGuid: string;
    baseUrl: string;
    checkEntryPoint: boolean;
    constructor() {
        this.appName = "Cash Gestora";
        this.version = "0.7.2"; //Versão do aplicativo
        // this.appGuid = "D7C8B9EF-CD97-40FB-BF8D-3BAF3102F6ED"; //produção
        this.appGuid = "1CB7EC50-B35E-4100-A4BC-AEAE0796062A" //devel 1CB7EC50-B35E-4100-A4BC-AEAE0796062A
        this.checkEntryPoint =false;
        // this.baseUrl = null; //URL básica do Serviço obtida através do entryPointUrl
        // this.baseUrl = "http://localhost:10804/api/"
        this.baseUrl = "https://cashgestora.atendeja.app.br/tms_svc/api/";
    }

}