import { Injectable } from '@angular/core';
import { BaseComunicationProvider } from '../providers/basecomunication/basecomunication';
import { HttpHeaders } from '@angular/common/http';
import { Customer, Customers, SwingTradesReport,DatasReport} from './../entities/dataCash'
@Injectable()
export class CustomerProvider extends BaseComunicationProvider {
  
    customers: any;
    customer: Customer;

    postCustomer(cust) {
        return new Promise((resolve, reject) => {
            this.presentLoadingTMS();
            /*let _headers = new HttpHeaders().set('tms-device',   this.variables.idDevice );
            if (this.variables.accessToken!= undefined) {
                _headers = _headers.set('Authorization', 'Bearer ' +this.variables.accessToken);
            }*/
            var _headers =this.httpHeaders();
            this.httpPost(this.settings.baseUrl + "Customer", _headers, { id: cust.id, idType: cust.idType, idValue: cust.idValue.replace('.', '').replace('.', '').replace('-', '').replace('/', ''), fullname: cust.fullname, nickName: cust.nickName, equity: cust.equity, contractLimitST: cust.contractLimitST, accountNumber: cust.accountNumber ,isActive:cust.isActive })
                .then((data: any) => {
                    this.dismissLoadingTMS();
                    resolve(this.requestResult)
                }).catch((erro) => {
                    this.dismissLoadingTMS();
                    reject(erro)
                })
        })
    }

    putCustomer(cust) {
        return new Promise((resolve, reject) => {
            this.presentLoadingTMS();
            var _headers =this.httpHeaders();

            this.httpPut(this.settings.baseUrl + "Customer", _headers, { id: cust.id, idType: cust.idType, idValue: cust.idValue.replace('.', '').replace('.', '').replace('-', '').replace('/', ''), fullname: cust.fullname, nickName: cust.nickName, equity: cust.equity, contractLimitST: cust.contractLimitST, accountNumber: cust.accountNumber,isActive:cust.isActive })
                .then((data: any) => {
                    this.dismissLoadingTMS();
                    resolve(this.requestResult)
                }).catch((erro) => {
                    this.dismissLoadingTMS();
                    reject(erro)
                })
        })
    }

    getCustomers() {
        return new Promise((resolve, reject) => {
            this.presentLoadingTMS();
            var _headers =this.httpHeaders();
            this.httpGet(this.settings.baseUrl + "Customer", _headers, '')
                .then((data: any) => {
                    this.customers = new Customers(data)
                    this.dismissLoadingTMS();
                    resolve(this.customers)
                }).catch((erro) => {
                    this.dismissLoadingTMS();
                    reject(erro)
                })
        })
    }

    swingTradesReport:SwingTradesReport;
    
    getSwingTradeReport() {
        return new Promise((resolve, reject) => {
            this.presentLoadingTMS();
            var _headers =this.httpHeaders();
            this.httpGet(this.settings.baseUrl + "SwingTradeReport", _headers, '')
                .then((data: any) => {
                    this.swingTradesReport = new SwingTradesReport(data)
                    this.dismissLoadingTMS();
                    resolve(this.swingTradesReport)
                }).catch((erro) => {
                    this.dismissLoadingTMS();
                    reject(erro)
                })
        })
    }



   // operationsReport:DatasReport;
    
getOperationsReport(parameters?:any) {
    return new Promise((resolve, reject) => {
        this.presentLoadingTMS();
        var _headers =this.httpHeaders();
        this.httpGet(this.settings.baseUrl + "OperationReport", _headers,parameters )
            .then((data: any) => {
           //    this.operationsReport = new DatasReport(data)
                this.dismissLoadingTMS();
                resolve( new DatasReport(data))
            }).catch((erro) => {
                this.dismissLoadingTMS();
                reject(erro)
            })
    })
}
//financialMovementReport:DatasReport;
getFinancialMovementReport(parameters?:any) {
    return new Promise((resolve, reject) => {
        this.presentLoadingTMS();
        var _headers =this.httpHeaders();
        this.httpGet(this.settings.baseUrl + "FinancialMovementReport", _headers,parameters )
            .then((data: any) => {
               // this.financialMovementReport = new DatasReport(data)
                this.dismissLoadingTMS();
                resolve( new DatasReport(data))
            }).catch((erro) => {
                this.dismissLoadingTMS();
                reject(erro)
            })
    })
}
}
