import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./page/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'operation',
    loadChildren: () => import('./page/operation/operation.module').then( m => m.OperationPageModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('./page/customer/customer.module').then( m => m.CustomerPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./page/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./page/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./page/report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'swingtradereport',
    loadChildren: () => import('./page/swing-trade-report/swing-trade-report.module').then( m => m.SwingTradeReportPageModule)
  },
  {
    path: 'operationreport',
    loadChildren: () => import('./page/operation-report/operation-report.module').then( m => m.OperationReportPageModule)
  },
  {
    path: 'financialmovementreport',
    loadChildren: () => import('./page/financial-movement-report/financial-movement-report.module').then( m => m.FinancialMovementReportPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
