import { Injectable } from '@angular/core';
import { BaseComunicationProvider } from '../providers/basecomunication/basecomunication';
import {User,Users} from './../entities/dataCash'
import { HttpHeaders } from '@angular/common/http';
@Injectable()
export class LoginProvider extends BaseComunicationProvider {

    //banks: Array<any> = new Array<any>({ nickName: "BTG" }, { nickName: "BTG1" }, { nickName: "BTG2" })
    banks: Array<any> = new Array<any>();
    postLogin(key, password) {
        return new Promise((resolve, reject) => {
            this.presentLoadingTMS();
            

            //let _headers = new HttpHeaders().set('tms-device', this.variables.idDevice );
            var _headers =this.httpHeaders();
            this.httpPost(this.settings.baseUrl + "Login", _headers, { key: key, password: password })
                .then((data: any) => {
                    this.variables.accessToken=data.Token;
                    this.setItem('accessToken', data.Token)
                   // alert(JSON.stringify(data));
                    this.dismissLoadingTMS();
                    resolve(data)
                    this.getBank();
                   // alert('Login '+JSON.stringify(this.banks));
                }).catch((erro) => {
                    this.dismissLoadingTMS();
                    reject(erro)
                })
        })
    }
    getBank() {
        return new Promise((resolve, reject) => {
            this.presentLoadingTMS();
            var _headers =this.httpHeaders();
            this.httpGet(this.settings.baseUrl + "Bank", _headers, '')
                .then((data: any) => {
                    this.banks=new Array<any>(data)[0]   
                    this.dismissLoadingTMS();
                    resolve( this.banks)
                }).catch((erro) => {
                    this.dismissLoadingTMS();
                    reject(erro)
                })
        })
    }

    users: any;
    user: User;

    postUser(user) {
        return new Promise((resolve, reject) => {
            this.presentLoadingTMS();
            var _headers =this.httpHeaders();
            this.httpPost(this.settings.baseUrl + "User", _headers, user)
                .then((data: any) => {
                    this.dismissLoadingTMS();
                    resolve(this.requestResult)
                }).catch((erro) => {
                    this.dismissLoadingTMS();
                    reject(erro)
                })
        })
    }

    putUser(user) {
        return new Promise((resolve, reject) => {
            this.presentLoadingTMS();
            var _headers =this.httpHeaders();
            this.httpPut(this.settings.baseUrl + "User", _headers, user)
                .then((data: any) => {
                    this.dismissLoadingTMS();
                    resolve(this.requestResult)
                }).catch((erro) => {
                    this.dismissLoadingTMS();
                    reject(erro)
                })
        })
    }

    getUsers() {
        return new Promise((resolve, reject) => {
            this.presentLoadingTMS();
            var _headers =this.httpHeaders();
            this.httpGet(this.settings.baseUrl + "User", _headers, '')
                .then((data: any) => {
                    this.users = new Users(data)
                    this.dismissLoadingTMS();
                    resolve(this.users)
                }).catch((erro) => {
                    this.dismissLoadingTMS();
                    reject(erro)
                })
        })
    }
}