
import { Injectable } from '@angular/core';
import { File } from '@ionic-native/file/ngx';

import { HttpClient, HttpHeaders  } from '@angular/common/http';

import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
  } from '@angular/common/http';
  


import { timeout, catchError } from 'rxjs/operators';
import { Variables } from '../../entities/variables';
import { Settings } from '../../entities/settings';
//import { Resource } from '../../entities/resource'
import { EntryPoint,ErroSystem,InfBase } from '../../entities/dataSystemBase';


import { Platform, LoadingController,AlertController } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { Network } from '@ionic-native/network/ngx';


import { LocalStorageService } from '../basecomunication/localstorage';


export { HttpClient, Variables, Settings, File,InfBase, ErroSystem, HttpHeaders }
@Injectable()

export abstract class HttpBaseProvider {
    requestResult: any = {};
    entryPoint: EntryPoint;
   

    constructor(public http: HttpClient, public variables: Variables, public settings: Settings, public file: File, public localStorage:LocalStorageService,
        public platform: Platform, public loadingCtrl: LoadingController,public alertController:AlertController,        
        public device: Device, public network: Network) {
            if( this.variables.infBase.length==undefined){
                this.setResource();
            }
        
    }
    public address: any
    getResourceMessage(name){
        //alert( ' || httpRethis.variables.infBase.resourcessponse: ' + JSON.stringify(this.variables.infBase.resources));
       return this.variables.infBase.resources.filter(frm=> frm.name== name)[0].messages.filter(fm=>fm.languagesId==this.variables.languageType)[0]
    }
    getResourceLabel(name){
        return this.variables.infBase.resources.filter(frm=> frm.name== name)[0].labels.filter(fm=>fm.languagesId==this.variables.languageType)[0]
     }
    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    setResource(){
        return new Promise((resolve, reject) => {
            this.httpGet("./assets/data/resources2.json?" + this.newGuid)
            .then((data: any) => {
                this.variables.infBase=new InfBase(data);
                resolve(this.requestResult)
            }).catch((erro) => {
             //   reject(new ErroSystem(error.status, error, 'fallha ao carregar.'))
                reject(erro)
            })
        })
    }
    log(){
        //this.file.dataDirectory
        this.file.writeFile('./assets/icon/', 'test.json', 'hello,world,', {replace: true})
        .then( data=>{
            alert( ' writeFile: ' + JSON.stringify(data));
            console.log('Directory exists')
        })
        .catch(err => {
            alert( ' writeFile err: ' + JSON.stringify(err));
            console.log('Directory doesn\'t exist')
        });
    }
    getItem(key){
        return this.localStorage.getItem(this.settings, key);
    }
    setItem(key,value){
        this.localStorage.setItem(this.settings, key, value)
    }
    removeItem(key){
            this.localStorage.removeItem(this.settings, key);        
    }

    httpError(error: any) {

        if (error.status == 400 || error.status == 401 || error.status == 403) {
            if (error.error != undefined && error.error.Message != undefined) {
                if (error.error.Message=="Authorization has been denied for this request."){
                    this.setItem('accessToken','Login');
                    this.variables.accessToken=undefined;
                }

               return new ErroSystem(error.status, error, error.error.Message)                
                //{ status: error.status, message: error.error.Message, isTest: false, item: error };
            } else {
                return new ErroSystem(error.status, error, error.Message)
                  //return { status: error.status, message: error.message, isTest: false, item: error };
            }
        } else if (error.status == 404) {
            return new ErroSystem(error.status, error, null, this.getResourceMessage("serverAPIHttpError404"))
        } else if (error.status == 405) {
            return new ErroSystem(error.status, error, null, this.getResourceMessage("serverAPIHttpError405"))
        } else if (error.status == 500) {
            return new ErroSystem(error.status, error, null, this.getResourceMessage("serverAPIHttpError500"))
        } else if (error.status == 0 && error.name == "HttpErrorResponse") {
            return new ErroSystem(error.status, error, null, this.getResourceMessage("serverAPIHttpError0"))
        } else {
            return new ErroSystem(error.status, error, 'Http:' + error.status + ' - ', this.getResourceMessage("serverAPINotRespond"))
        }

    }
    httpResponse(response: any) {
        // alert(response.status + ' || httpResponse: ' + JSON.stringify(response));
        return response
    }
    httpHeaders(){
        var headers = new HttpHeaders();
       
        if (this.variables.idDevice!= undefined) {
            headers=  headers.set('tms-device',   this.variables.idDevice );
        }
       
        if (this.variables.accessToken!= undefined) {
            headers=  headers.set('Authorization', 'Bearer ' +this.variables.accessToken);
        }
        return headers
    } 
    httpGet(url, headers?, parameters?) {
        return new Promise((resolve, reject) => {
            //    let url = this.setting     s.baseUrl + this.address + '/' + operation;
            var options:any={};
            if( headers != undefined && parameters != undefined){
                options={ headers: headers, params: parameters }
            }else if(headers != undefined ){
                options={ headers: headers }
            }else if(parameters != parameters){
                options={params: parameters }
            }else{
                
            }

            this.http.get(url, options)
                .pipe(timeout(this.variables.timeout))
                .subscribe(data => {
                    resolve(this.httpResponse(data));
                }, error => {
                    reject(this.httpError(error));
                });
        });
    }

    httpPut(url, headers?, body?) {
        return new Promise((resolve, reject) => {
           
            //let url = this.settings.baseUrl + this.address + '/' + operation;
            this.http.put(url, body, { headers: headers })
                .pipe(timeout(this.variables.timeout))
                .subscribe(data => {
                    resolve(this.httpResponse(data));
                }, error => {
                    reject(this.httpError(error));
                });
        });
    }

    httpPost(url, headers?, body?) {
        return new Promise((resolve, reject) => {
            //    let url = this.settings.baseUrl + this.address + '/' + operation;
            this.http.post(url, body, { headers: headers })
                .pipe(timeout(this.variables.timeout))
                .subscribe(data => {
                    resolve(this.httpResponse(data));
                }, error => {
                    reject(this.httpError(error));
                });
        });
    }

    httpDelete(url, headers?, parameters?) {
        //  let url = this.settings.baseUrl + this.address + '/' + operation;
        return new Promise((resolve, reject) => {
            this.http.delete(url, { headers: headers, params: parameters })
                .pipe(timeout(this.variables.timeout))
                .subscribe(data => {
                    resolve(this.httpResponse(data));
                }, error => {
                    reject(this.httpError(error));
                });
        });
    }

    listEntryPointUrl() {
        return new Promise((resolve, reject) => {
            var erroEntryPointUrls: Array<any> = new Array<any>();
            try {
                var _entryPointUrls: any = this.variables.entryPointUrls.filter(u => u.appGuid == null)
                if (_entryPointUrls.length != 0) {
                    this.variables.entryPointUrls.map(entryPointUrl => {
                        let headersEP = new HttpHeaders()
                        entryPointUrl.headers.map(h => {
                            headersEP = headersEP.set(h.name, h.value);
                        })
                        this.httpPost(entryPointUrl.url, headersEP, entryPointUrl.body)
                            .then((data: any) => {
                                this.entryPoint = new EntryPoint(data.item);
                                entryPointUrl.entryPoint = this.entryPoint;
                                if (data.status == "success" && this.settings.baseUrl == undefined) {
                                    this.settings.baseUrl = this.entryPoint.address
                                    this.setItem('baseUrl',this.settings.baseUrl)
                                    resolve(this.settings.baseUrl)
                                } else if (data.status == "error") {
                                    erroEntryPointUrls.push(data);
                                    if (this.variables.entryPointUrls.length == erroEntryPointUrls.length) {
                                        reject(erroEntryPointUrls)
                                    }
                                }
                            })
                            .catch(error => {
                                erroEntryPointUrls.push(error);
                                if (this.variables.entryPointUrls.length == erroEntryPointUrls.length) {
                                    reject(erroEntryPointUrls)
                                }
                            })
                    })
                } else {
                    erroEntryPointUrls.push(new ErroSystem('falha', null, null, this.getResourceMessage("serverAPIListEntryPointNotfound")));
                    reject(erroEntryPointUrls)
                }
            } catch (error) {
                erroEntryPointUrls.push(new ErroSystem('falha', null, null, this.getResourceMessage("serverAPIErrorEntryPoint")));
                reject(erroEntryPointUrls)
            }
        })
    }
}