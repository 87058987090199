import { Component,  } from '@angular/core';
//import {  ModalController, NavParams,ToastController,PopoverController} from '@ionic/angular';


import { MainPage,   OnInit, Platform,
  ActivatedRoute, Router, NgZone, NavigationExtras, NavParams,
  NavController, ToastController, AlertController, ModalController, ParamMap, switchMap, Observable,InAppBrowser,Printer,
  LoginProvider,ImporterProvider,GenerateWorkbookProvider,CustomerProvider,
  ResponsePlanilha, ContractLimit, ContractLimitsST, Customer, Customers, Operation, Operations, SummaryOperation, SummaryOperations} from '../../mainpage';

@Component({
  selector: 'app-select-customer',
  templateUrl: './select-customer.page.html',
  styleUrls: ['./select-customer.page.scss'],
})
export class SelectCustomerPage  extends MainPage   implements OnInit {
  selectCust:Array<any>;
  sumEquityCust:any=0;
  selectCustRet:Array<any>=new Array<any>();
  //public modalCtrl: ModalController,  private popCtrl  : PopoverController,  private navParams: NavParams,
  propsType : string;
  operationType:any;
  allCheck:boolean=false;
  constructor(
    public route: ActivatedRoute, public ngZone: NgZone,
        public router: Router, protected navCtrl: NavController, protected toastCtrl: ToastController,
        protected alertCtrl: AlertController, public modalCtrl: ModalController, public iab: InAppBrowser,
        public loginPro: LoginProvider, public importerPro: ImporterProvider, public generateWorkbookPro:GenerateWorkbookProvider,
        public customerPro :CustomerProvider, public printer: Printer,
        private navParams: NavParams

    ) { 
      super(route,ngZone,router,navCtrl,toastCtrl,alertCtrl,modalCtrl,iab,loginPro,importerPro,generateWorkbookPro,customerPro,printer)
      this.propsType = this.navParams.data.propsType;
    }

  ngOnInit() {
    this.selectCust= this.navParams.data.selectCustomer;
    this.operationType=this.navParams.data.operationType;
    this.selectCust.filter(fo => fo.check == true || fo.operationType != undefined).map(mo => {mo.check = false; mo.operationType = undefined});
    this.allCheck =false;
   // alert('ionViewWillEnter: '+JSON.stringify( this.selectCust));
  }
  ionViewWillEnter(){
    

    
  }
  setAllCheck(){
    this.allCheck = !this.allCheck;
    this.selectCust.filter(fo => fo.check == !this.allCheck || fo.operationType != undefined)
    .map(mo => {mo.check = this.allCheck; mo.operationType = undefined});

  }
  closeModal()
  {
    this.modalCtrl.dismiss({sumEquityCust:this.sumEquityCust,customers:this.selectCust});
  }
  setCheck(customer){
    if (customer.check == false){
      customer.check= true;
      customer.operationType=this.operationType;
      this.sumEquityCust=this.sumEquityCust+customer.equity;
      this.selectCustRet.push(customer)
     
    }else {
      this.sumEquityCust=this.sumEquityCust-customer.equity;
      if (this.sumEquityCust<0){
        this.sumEquityCust=0;
      }
      //var _cust=this.selectCustRet.filter(c=> c.id == customer.id)[0]
      let index = this.selectCustRet.indexOf(customer);
      this.selectCustRet.splice(index, 1);
      customer.operationType=undefined;
      customer.check= false;
    }

  }
}
