import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Settings } from '../entities/settings'
import { Mask, DateTms } from '../entities/dataSystemBase'


@Injectable()



export class BankCodes {
    constructor(items?: any) {
        var auxItems:any =new Array();
          if (items != undefined) {
            items.map(item => {
                let index = auxItems.indexOf(item.bankCode);
              if(index ==-1){
                auxItems.push(item.bankCode )
              }                
            })
        }
        return auxItems;
    }
}
export class ResponsePlanilha {
    identifier: string;
    bank: string;
    operations: Operations;
    summary: SummaryOperations;
    customers: Customers;
    bankCodes:BankCodes;
    constructor(items?: ResponsePlanilha, bank?: any) {
        if (items != undefined) {
            this.bank = bank;
            this.identifier = items.identifier;
            this.operations = new Operations(items.operations);
            this.summary = new SummaryOperations(this.operations);    
            this.customers = new Customers(items.customers);
            this.bankCodes = new BankCodes(items.operations);
          
        }
    }

}
export class GenerateWorkbook {
    identifier: string;
    bank: string;
    bankCode: number;
    items: Array<{ customerId: number, operationType: string, bankCode: number, items: Array<{ operationId: number, quantity: number, }> }> = new Array<{ customerId: number, operationType: string, bankCode: number,items: Array<{ operationId: number, quantity: number }> }>()
    constructor(identifier: string, bank: string, items?: any) {
        if (identifier != undefined && bank != undefined && items != undefined) {
            this.items = new Array<{ customerId: number, operationType: string,   bankCode: number, items: Array<{ operationId: number, quantity: number }> }>()
            this.identifier = identifier;
            this.bank = bank;
            items.map(item => {
                var _operations: Array<{ operationId: number, quantity: number }> = new Array<{ operationId: number, quantity: number }>();
                item.operations.map(io => {
                    _operations.push({ operationId: io.id, quantity: io.amount })
                })
                this.items.push({ customerId: item.id, operationType:  item.operationType, bankCode:  item.bankCode, items: _operations });
            })
        }
    }
}

export class Customer {
    id: number;
    idType: number;
    idValue: string;
    fullname: string;
    nickName: string;
    equity: number;
    accountNumber: number;
    contractLimitST: number;
    check: boolean;
    contractLimitsST: ContractLimitsST;
    operations: Operations;
    summaryOperations: SummaryOperations;
    operationType: string;
    isActive: boolean = true;
    bankCode:number;
    constructor(items?: any) {
        if (items != undefined) {
            this.check = items.check || false;
            this.id = items.id ?? 0
            this.idType = items.idType ?? '';
            var _idValue = new Mask().maskCPF_CNPJ(items.idValue).idValueMask || '';
            this.idValue = _idValue;
            //this.idValue = items.idValue ?? '';
            this.fullname = items.fullname ?? '';
            this.nickName = items.nickName ?? '';
            this.equity = items.equity ?? 0;
            this.contractLimitST = items.contractLimitST ?? '';
            this.isActive = items.isActive ?? true;
            this.accountNumber = items.accountNumber ?? '';
            var aux: any = [{ type: "WIN", limit: items.contractLimitST }];
            this.contractLimitsST = new ContractLimitsST(items.contractLimits || aux);
            this.operations = new Operations(items.operations);
            this.summaryOperations = new SummaryOperations(this.operations);
            this.operationType = items.operationType;
            this.bankCode=items.bankCode;
        }
    }
}
export class Customers {

    constructor(items?: any) {
        var auxItems: Array<Customer> = new Array<Customer>();
        if (items != undefined) {
            items.map(item => {
                auxItems.push(new Customer(item));
            })
        }
        return auxItems;
    }
}
export class ContractLimit {
    type: string;
    limit: number;
    constructor(items?: any) {
        if (items != undefined) {
            this.type = items.type;
            this.limit = items.limit;
        }
    }
}
export class ContractLimitsST {
    constructor(items?: any) {
        var auxItems: Array<ContractLimit> = new Array<ContractLimit>();
        if (items != undefined) {
            items.map(item => {
                auxItems.push(new ContractLimit(item));
            })
        }
        return auxItems;
    }
}

export class Workbook {
    private customersNew: Array<any> = new Array<any>();
    private lengthOper: any = 0;
    private operationsBefore: Operations;
    operations: Operations;
    customersOperations: Customers;
    summaryOperationsAux: any;
    sumEquityCust: any;
    constructor(operationType: any, operations: any, customers: any, customersOperations: any,bankCodesId:any) {
        this.operationsBefore = new Operations(operations);
        this.sumEquityCust = 0;
        try {
            if (operationType != undefined && operations != undefined && customers != undefined && customers.filter(fc => fc.check == true).length > 0) {
                customers.filter(fc => fc.check == true).sort((a, b) => a.equity - b.equity)
                    .map(cust => {
                        this.sumEquityCust += cust.equity;
                        var co = customersOperations.filter(fco => fco.id == cust.id && fco.operationType == operationType && fco.bankCode==bankCodesId)
                        if (co.length > 0) {
                            this.customersNew.push(co[0]);
                            let index = customersOperations.indexOf(co[0]);
                            customersOperations.splice(index, 1);
                        } else {
                            this.customersNew.push(new Customer({ id: cust.id, idType: cust.idType, idValue: cust.idValue, fullname: cust.fullname, nickName: cust.nickName, equity: cust.equity, check: cust.check, contractLimitsST: cust.contractLimitsST, operationType: cust.operationType || operationType,bankCode:bankCodesId }))
                        }
                    })
                this.summaryOperationsAux = new SummaryOperationsUserAmount(operations.filter(op => op.check == true && op.amount > op.userAmount));
                if (operationType == "DT") {
                    this.summaryOperationsAux.map(so => {
                        if (so.dayTrade > 0) {
                            var dayTradeC = so.dayTrade; var dayTradeV = so.dayTrade;
                            this.lengthOper = operations.filter(op => op.inputStockCode == so.inputStockCode && op.tradingType == "V" && op.check == true && op.amount > op.userAmount).length;
                            operations.filter(op => op.inputStockCode == so.inputStockCode && op.tradingType == "C" && op.check == true && op.amount > op.userAmount)
                                .sort((a, b) => a.amount - b.amount)
                                .map(iOp => {
                                    var o = new Operation(iOp);
                                    if ((iOp.amount - iOp.userAmount) <= dayTradeC && dayTradeC > 0) {
                                        o.amount = (iOp.amount - iOp.userAmount);
                                        dayTradeC = dayTradeC - (iOp.amount - iOp.userAmount);
                                        iOp.userAmount = iOp.userAmount + (iOp.amount - iOp.userAmount)
                                        new WorkbookCustomersOperations(this.customersNew, o, this.sumEquityCust, -1)
                                        //  iOp.userAmount += o.amount;
                                    } else if ((iOp.amount - iOp.userAmount) > dayTradeC && dayTradeC > 0) {
                                        iOp.userAmount = iOp.userAmount + dayTradeC;
                                        o.amount = dayTradeC;
                                        dayTradeC = 0;
                                        new WorkbookCustomersOperations(this.customersNew, o, this.sumEquityCust, -1)
                                        //iOp.userAmount += o.amount;
                                    }

                                })

                            operations.filter(op => op.inputStockCode == so.inputStockCode && op.tradingType == "V" && op.check == true && op.amount > op.userAmount)
                                .sort((a, b) => a.amount - b.amount)
                                .map(iOp => {
                                    this.lengthOper -= 1;
                                    var o = new Operation(iOp);
                                    if ((iOp.amount - iOp.userAmount) <= dayTradeV && dayTradeV > 0) {
                                        o.amount = (iOp.amount - iOp.userAmount);
                                        dayTradeV = dayTradeV - (iOp.amount - iOp.userAmount);
                                        iOp.userAmount = iOp.userAmount + (iOp.amount - iOp.userAmount)
                                        new WorkbookCustomersOperations(this.customersNew, o, this.sumEquityCust, dayTradeV > 0 ? -1 : 0)
                                        // iOp.userAmount += o.amount;
                                    } else if ((iOp.amount - iOp.userAmount) > dayTradeV && dayTradeV > 0) {
                                        iOp.userAmount = iOp.userAmount + dayTradeV;
                                        o.amount = dayTradeV;
                                        dayTradeV = 0;
                                        new WorkbookCustomersOperations(this.customersNew, o, this.sumEquityCust, dayTradeV > 0 ? -1 : 0)
                                        //   iOp.userAmount += o.amount;
                                    }

                                })

                        } else {
                            operations.filter(op => op.inputStockCode == so.inputStockCode && op.check == true && op.amount > op.userAmount)
                                .map(iOp => {
                                    var o = new Operation(iOp);
                                    o.amount = (iOp.amount - iOp.userAmount);
                                    iOp.userAmount = iOp.userAmount + (iOp.amount - iOp.userAmount)
                                    new WorkbookCustomersOperations(this.customersNew, o, this.sumEquityCust, -1)
                                    // iOp.userAmount += o.amount;
                                })
                        }
                    })
                } else {
                    operations.filter(op => op.check == true && op.amount > op.userAmount)
                        .map(iOp => {
                            var o = new Operation(iOp);
                            o.amount = (iOp.amount - iOp.userAmount);
                            //  iOp.userAmount = iOp.userAmount + (iOp.amount - iOp.userAmount)
                            new WorkbookCustomersOperations(this.customersNew, o, this.sumEquityCust, -1)
                            iOp.userAmount += o.amount;
                        })
                }
                if (customersOperations.length > 0) {
                    customersOperations.map(mco => this.customersNew.push(mco))
                }

                this.customersOperations = new Customers(this.customersNew.filter(fo => fo.operations.length > 0));
                this.operations = operations;

            } else {
                this.operations = this.operationsBefore;
                this.customersOperations = customersOperations;
            }
        } catch (err) {
            this.operations = this.operationsBefore;
            this.customersOperations = customersOperations;
        }
    }


}
export class s{
    constructor(customers: any,inputStockCode:any){
        customers.filter(fcs =>{fcs.summaryOperations.filter(fso=>{fso.inputStockCode == inputStockCode && fso.billed !=fso.selled})} )
    }
}
export class WorkbookCustomersOperations {
    private amountRounding: number = 0;
    private lengthCust: number;
    private quantityAdjustment: number = 0;
    constructor(customers: any, operation: any, sumEquityCust: any, lengthOper?: any) {
        this.amountRounding = 0;

        this.lengthCust = customers.length;
        customers.sort((a, b) =>  a.equity-b.equity).map(custN => {
            this.lengthCust -= 1;
            var co = new Operation(operation);
            var amount: number = ((operation.amount * (custN.equity * 100 / sumEquityCust)) / 100)+this.amountRounding;
            var amountInt = parseInt(amount.toString())
            if (this.lengthCust == 0) {
                var amountInt = parseInt(amount.toFixed(0).toString())
            }
            this.amountRounding = (amount - amountInt)
            var amountSumC = 0;
            var amountSumV = 0;
            if (amountInt > 0) {               
                 if (lengthOper == 0) {
                     custN.operations.filter(f => f.tradingType == "C").map(C => {
                         amountSumC += C.amount
                     })
                     custN.operations.filter(f => f.tradingType == "V").map(C => {
                         amountSumV += C.amount
                     })
                     var _qAdj=(amountSumV + amountInt)-amountSumC
                     
                    if( _qAdj != 0){
                        co.amount= amountInt-_qAdj;
                    }else{
                        co.amount= amountInt;
                    }
                    this.quantityAdjustment += co.amount;
                }else{
                    co.amount = amountInt;
                }
                // operation.amount -= co.amount;
                operation.userAmount += co.amount;
                if (co.amount > 0) {
                    custN.operations.push(co)
                }
                custN.summaryOperations = new SummaryOperations(custN.operations);
            }
        })
        if(operation.userAmount != operation.amount){
            customers.sort((a, b) =>  a.equity-b.equity).map(custN => {
                var amountSumC = 0;
                var amountSumV = 0;
                custN.operations.filter(f => f.tradingType == "C").map(C => {
                    amountSumC += C.amount
                })
                custN.operations.filter(f => f.tradingType == "V").map(C => {
                    amountSumV += C.amount
                })
                var co = new Operation(operation);
                co.amount=0;
               
                if ((amountSumC - amountSumV)==  (operation.amount - operation.userAmount)){
                    co.amount=amountSumC - amountSumV
                }else{
                    co.amount=undefined;
                }

                operation.userAmount += co.amount;
                if (co.amount > 0) {
                    custN.operations.push(co)
                }
                custN.summaryOperations = new SummaryOperations(custN.operations);
                //var edtoperation:any =custN.operations.filter(f=>{f.id ==operation.id});

 
            })
        }
    }
}

export class WorkbookCustomersOperations2 {
    private amountRounding: number = 0;
    private lengthCust: number;

    constructor(customers: any, operation: any, sumEquityCust: any, lengthOper?: any) {
        this.amountRounding = 0
        this.lengthCust = customers.length;
        customers.sort((a, b) => b.equity - a.equity).map(custN => {
            this.lengthCust -= 1;
            var co = new Operation(operation);
            var amount: number = ((operation.amount * (custN.equity * 100 / sumEquityCust)) / 100);
            var amountInt = parseInt(amount.toString())
            this.amountRounding += (amount - amountInt)
            var amountSumC = 0;
            var amountSumV = 0;
            if ((amountInt + parseInt(this.amountRounding.toFixed(0))) > 0) {
                if (this.lengthCust > 0) {
                    co.amount = amountInt;
                } else {
                    co.amount = amountInt + parseInt(this.amountRounding.toFixed(0));
                }
                if (lengthOper == 0) {
                    custN.operations.filter(f => f.tradingType == "C").map(C => {
                        amountSumC += C.amount
                    })
                    custN.operations.filter(f => f.tradingType == "V").map(C => {
                        amountSumV += C.amount
                    })
                    if (amountSumC != amountSumV) {
                        co.amount += amountSumC - (amountSumV + co.amount);
                    }
                }
                // operation.amount -= co.amount;
                operation.userAmount += co.amount;
                if (co.amount > 0) {
                    custN.operations.push(co)
                }

                custN.summaryOperations = new SummaryOperations(custN.operations);
            }

        })
    }
}
export class SummaryOperation {
    inputStockCode: string;
    billed: number;
    selled: number;
    billedPrice: number;
    selledPrice: number;
    balance: number;
    dayTrade: number;
    swingTrade: number;
    status: number;
    bankCode: number;
    constructor(items?: any) {
        if (items != undefined) {
            this.inputStockCode = items.inputStockCode;
            this.billed = items.billed;
            this.selled = items.selled;
            this.billedPrice = items.billedPrice;
            this.selledPrice = items.selledPrice;
            this.balance = this.billed - this.selled;
            if (this.balance > 0) {
                this.swingTrade = this.balance;
            } else if (this.balance < 0) {
                this.swingTrade = this.balance * (-1);
            }
            this.dayTrade = (this.billed + this.selled) - this.swingTrade;
            this.bankCode = items.bankCode;

        }
    }
}
export class SummaryOperationsUserAmount {
    constructor(items?: any) {
        var auxItems: Array<SummaryOperation> = new Array<SummaryOperation>();
        var bankCodes:any =new BankCodes(items);
        if (items != undefined) {
            bankCodes.map(bc=>{
                items.filter(i=> i.bankCode==bc).map(item => {
                    if (auxItems.filter(i => i.inputStockCode == item.inputStockCode  &&  i.bankCode==bc).length == 0) {
                        if (item.tradingType == "C") {
                            auxItems.push(new SummaryOperation({
                                inputStockCode: item.inputStockCode,
                                billed: item.amount-item.userAmount,
                                selled: 0,
                                billedPrice: item.price*(item.amount-item.userAmount),
                                selledPrice: 0,
                                bankCode:item.bankCode
                            }));
                        } else {
                            auxItems.push(new SummaryOperation({
                                inputStockCode: item.inputStockCode,
                                billed: 0,
                                selled: item.amount-item.userAmount,
                                billedPrice: 0,
                                selledPrice: item.price*(item.amount-item.userAmount),
                                bankCode:item.bankCode
                            }));
                        }
                    } else {
                        var i = auxItems.filter(i => i.inputStockCode == item.inputStockCode &&  i.bankCode==bc)[0]
                        if (item.tradingType == "C") {
                            i.billed += item.amount-item.userAmount;
                            i.billedPrice += item.price*(item.amount-item.userAmount);
                        } else {
                            i.selled += item.amount -item.userAmount;
                            i.selledPrice += item.price*(item.amount-item.userAmount);
                        }
                        i.balance = i.billed - i.selled;
                        if ((i.billed - i.selled) > 0) {
                            i.swingTrade = i.billed - i.selled;
                        } else if ((i.billed - i.selled) < 0) {
                            i.swingTrade = i.selled - i.billed;
                        } else {
                            i.swingTrade = 0
                        }
                        i.dayTrade = ((i.billed + i.selled) - i.swingTrade) / 2;
                        if (i.billed == i.selled) {
                            i.status = 0;
                        } else if (i.billed > i.selled) {
                            i.status = 1;
                        } else if (i.billed < i.selled) {
                            i.status = 2;
                        } else {
                            i.status = -1;
                        }
                    }
                })
            })

        }
        return auxItems;
    }
}

export class SummaryOperations {
    constructor(items?: any) {
        var auxItems: Array<SummaryOperation> = new Array<SummaryOperation>();
        var bankCodes:any =new BankCodes(items);
        if (items != undefined) {
            
            bankCodes.map(bc=>{
                items.filter(i=> i.bankCode==bc).map(item => {
                    if (auxItems.filter(i => i.inputStockCode == item.inputStockCode &&  i.bankCode==bc).length == 0) {
                        if (item.tradingType == "C") {
                            auxItems.push(new SummaryOperation({
                                inputStockCode: item.inputStockCode,
                                billed: item.amount,
                                selled: 0,
                                billedPrice: item.price*item.amount,
                                selledPrice: 0,
                                bankCode:item.bankCode
                            }));
                        } else {
                            auxItems.push(new SummaryOperation({
                                inputStockCode: item.inputStockCode,
                                billed: 0,
                                selled: item.amount,
                                billedPrice: 0,
                                selledPrice: item.price*item.amount,
                                bankCode:item.bankCode
                            }));
                        }
                    } else {
                        var i = auxItems.filter(i => i.inputStockCode == item.inputStockCode &&  i.bankCode==bc)[0]
                        if (item.tradingType == "C") {
                            i.billed += item.amount;
                            i.billedPrice += item.price*item.amount;
                        } else {
                            i.selled += item.amount;
                            i.selledPrice += item.price*item.amount;
                        }
                        i.balance = i.billed - i.selled;
                        if ((i.billed - i.selled) > 0) {
                            i.swingTrade = i.billed - i.selled;
                        } else if ((i.billed - i.selled) < 0) {
                            i.swingTrade = i.selled - i.billed;
                        } else {
                            i.swingTrade = 0
                        }
                        i.dayTrade = ((i.billed + i.selled) - i.swingTrade) / 2;
                        if (i.billed == i.selled) {
                            i.status = 0;
                        } else if (i.billed > i.selled) {
                            i.status = 1;
                        } else if (i.billed < i.selled) {
                            i.status = 2;
                        } else {
                            i.status = -1;
                        }
                    }
                })
            });

        }
        return auxItems;
    }
}

export class Operation {
    id: number;
    inputStockCode: string;
    tradingType: string;
    price: number;
    amount: number;
    userAmount: number;
    percent: number;
    check: boolean;
    priceAmount: number;
    bankCode: number;

    constructor(items?: any) {
        if (items != undefined) {
            this.check = items.check || false;
            this.id = items.id;
            this.inputStockCode = items.inputStockCode;
            this.tradingType = items.tradingType;
            this.price = items.price;
            this.amount = items.amount;
            this.bankCode = items.bankCode;
            this.userAmount = items.userAmount || 0;
            this.percent = this.userAmount * 100 / this.amount;
            this.priceAmount = this.price * this.amount
        }
    }

}
export class Operations {
    constructor(items?: any) {
        var auxItems: Array<Operation> = new Array<Operation>();
        if (items != undefined) {
            items.map(item => {
                auxItems.push(new Operation(item));
            })
        }
        return auxItems;
    }
}

export class User {
    userId: number;
    login: string;
    fullname: string;
    password: string;
    constructor(items?: any) {

        this.userId = items?.userId ?? 0;
        this.login = items?.login ?? '';
        this.fullname = items?.fullname ?? '';
        this.password = items?.password ?? '';


    }
}
export class Users {
    constructor(items?: any) {
        var auxItems: Array<User> = new Array<User>();
        if (items != undefined) {
            items.map(item => {
                auxItems.push(new User(item));
            })
        }
        return auxItems;
    }
}

export class SwingTradeReport {
    customer: string;
    quantity: number;
    tradingType: string;
    price: number;
    stockCode: string;
    lastTransactionDate: Date;

    constructor(items?: any) {
        this.customer = items?.customer ?? '';
        this.quantity = items?.quantity ?? 0;
        this.tradingType = items?.tradingType ?? '';
        this.price = items?.price ?? '';
        this.stockCode = items?.stockCode ?? '';
        this.lastTransactionDate = items?.lastTransactionDate ?? new DateTms(items?.lastTransactionDateStr).date;
    }
}
export class SwingTradesReport {
    constructor(items?: any) {
        var auxItems: Array<SwingTradeReport> = new Array<SwingTradeReport>();
        if (items != undefined) {
            items.map(item => {
                auxItems.push(new SwingTradeReport(item));
            })
        }
        return auxItems;
    }
}
export class DataReport {
    customer: string;
    quantity: number;
    tradingType: string;
    price: number;
    stockCode: string;
    operationDate: Date;
    tradingDate: Date;
    operationType: string;
    bank: string;
    financial: number;

    constructor(items?: any) {
        this.customer = items?.customer ?? '';
        this.quantity = items?.quantity ?? 0;
        this.tradingType = items?.tradingType ?? '';
        this.price = items?.price ?? '';
        this.financial = items?.financial ?? '';
        this.stockCode = items?.stockCode ?? '';
        this.operationType = items?.operationType ?? '';
        this.bank = items?.bank ?? '';
        this.operationDate = items?.operationDate ?? new DateTms(items?.operationDate).date;
        this.tradingDate = items?.tradingDate ?? new DateTms(items?.operationDate).date;
    }
}

export class DatasReport {
    constructor(items?: any) {
        var auxItems: Array<DataReport> = new Array<DataReport>();
        if (items != undefined) {
            items.map(item => {
                auxItems.push(new DataReport(item));
            })
        }
        return auxItems;
    }
}